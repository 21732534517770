import { coerceBooleanProperty } from '@angular/cdk/coercion'
import { DatePipe, JsonPipe, NgClass, NgStyle, TitleCasePipe } from '@angular/common'
import { ChangeDetectionStrategy, Component, booleanAttribute, computed, inject, input, model } from '@angular/core'
import { MatDivider } from '@angular/material/divider'
import { MatIcon } from '@angular/material/icon'
import { TranslocoModule, TranslocoService } from '@ngneat/transloco'
import { TranslocoLocaleModule } from '@ngneat/transloco-locale'
import { injectMutation, injectQueryClient } from '@tanstack/angular-query-experimental'
import { firstValueFrom } from 'rxjs'
import { ChannelLogoComponent } from 'src/app/common/components/channel-logo/channel-logo.component'
import { IconOutlined, Icon } from 'src/app/common/icons'
import { Nl2brPipe } from 'src/app/common/pipes/nl2br.pipe'
import { ButtonComponent } from 'src/app/common/ui/button/button.component'
import { CardComponent } from 'src/app/common/ui/card/card.component'
import { MenuComponent, MenuItem } from 'src/app/common/ui/menu/menu.component'
import { TextComponent } from 'src/app/common/ui/text/text.component'
import { TooltipComponent } from 'src/app/common/ui/tooltip/tooltip.component'

import { AvatarComponent } from '~common/ui/avatar/avatar.component'
import { UserService } from '~core/services'
import { DrawerService } from '~core/services/ui/drawer.service'
import { SnackbarService } from '~core/services/ui/snackbar.service'
import { CommentsApi } from '~features/comments/services/comments.api'
import { User } from '~features/user/models/user.model'

import { Comment, Sentiment, SentimentType } from '../../types'
import { CommentThreadComponent } from '../comment-thread/comment-thread.component'
import { SentimentIconComponent } from '../sentiment-icon/sentiment-icon.component'

type CommentMenuAction = {
  type: 'TOGGLE_BOOKMARK' | 'CHANGE_SENTIMENT'
  args?: { sentiment: Omit<SentimentType, 'UNCLASSIFIED'> }
}
type CommentMenuItem = MenuItem & { action?: CommentMenuAction; children?: Omit<CommentMenuItem, 'children'>[] }

type ChangeSentimentPayload = {
  commentId: string
  sentiment: Omit<SentimentType, 'UNCLASSIFIED'>
}

@Component({
  selector: 'sb-comment-item',
  standalone: true,
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [
    CardComponent,
    TextComponent,
    ButtonComponent,
    SentimentIconComponent,
    JsonPipe,
    Nl2brPipe,
    DatePipe,
    TranslocoModule,
    TranslocoLocaleModule,
    MatDivider,
    MatIcon,
    TooltipComponent,
    NgStyle,
    ChannelLogoComponent,
    NgClass,
    MenuComponent,
    TitleCasePipe,
    AvatarComponent,
  ],
  template: `
    <sb-card
      *transloco="let t"
      [contentStyles]="contentStyles()"
      [flat]="variant() === 'compact'"
      [clickable]="variant() === 'compact'"
      [selectable]="selectable()"
      [selected]="selected()?.id === comment().id"
      (click)="onCommentClick()"
    >
      @if (variant() === 'default') {
        <ng-template #title>
          <div class="flex items-center justify-between">
            <div class="flex items-center gap-2">
              @if (authorIsOwner()) {
                @if (comment().ownerPicture) {
                  <sb-avatar size="sm" [src]="comment().ownerPicture" />
                } @else {
                  <sb-channel-logo size="sm" [channel]="ownerChannel()" hideType />
                }
              }
              <div class="h-fit">
                <sb-text noMargin>
                  {{ '@' + comment().authorName }}
                </sb-text>
                <sb-text variant="label" muted noMargin>
                  {{ comment().publishedAt | translocoDate }} - {{ comment().publishedAt | date: 'HH:mm' }}
                </sb-text>
              </div>
            </div>
            @if (!hideActions()) {
              <sb-menu
                class="max-w-xs"
                [options]="menuOptions()"
                xPosition="before"
                (selectionChanged)="handleAction($event)"
                selectable
              >
                <ng-template #labelTemplate>
                  <sb-button variant="icon" [icon]="Icons.MoreVert" />
                </ng-template>
              </sb-menu>
              <!-- 
                [tooltip]="t('comments.actions.' + (isBookmarked() ? 'RemoveFromBookmarks' : 'AddToBookmarks'))"
                (sbClick)="onBookmarkClick($event)"
                [disabled]="toggleBookmark.isPending()"
             -->
            }
          </div>
          @if (comment().channelReplyCount) {
            <div class="flex items-center gap-1">
              @if (comment().ownerPicture) {
                <sb-avatar size="xxs" [src]="comment().ownerPicture" />
              } @else {
                <sb-channel-logo size="xxs" [channel]="ownerChannel()" hideType />
              }
              <sb-text variant="label" muted noMargin>
                {{ t('comments.ui.ChannelHasReplied', { count: comment().channelReplyCount }) }}
              </sb-text>
            </div>
          }
        </ng-template>
        <ng-template #content>
          <div class="mb-3">
            <sb-text [innerHTML]="comment().message | nl2br: 'multiple'" noMargin />
            @if (!hideThreadToggle() && hasThread()) {
              <sb-text
                (click)="onOpenThread()"
                [customStyle]="{ display: 'inline-flex', alignItems: 'center', gap: '.25rem', lineHeight: 1 }"
                variant="labelLarge"
                class="pointer text-primary mt-3 inline-flex border-0 border-b border-solid"
                noMargin
              >
                <span>Mostra conversazione</span>
                <mat-icon [svgIcon]="Icons.ReplyAll" [ngStyle]="{ width: '14px', height: '14px' }" color="inherit" />
              </sb-text>
            }
          </div>
          <mat-divider />
          <div class="mt-2 flex items-center justify-between gap-2">
            <div class="flex items-center gap-1">
              <sb-tooltip [content]="t('shared.ui.Sentiment')">
                <sb-sentiment-icon [sentiment]="sentiment()" variant="text" />
              </sb-tooltip>
              @if (!hideInReview() && comment().sentimentInReview) {
                <sb-tooltip
                  [content]="
                    t('comments.info.CommentSentimentIsInReview', {
                      sentiment: t('sentiment.ui.' + (comment().proposedSentiment | titlecase))
                    })
                  "
                >
                  <mat-icon [svgIcon]="Icons.Error" [ngStyle]="{ width: '20px', height: '20px' }" />
                </sb-tooltip>
              }
            </div>
            <div class="flex items-center gap-2">
              @if (!hideBookmark() && isBookmarked()) {
                <sb-tooltip position="above" [content]="t('comments.fields.isBookmarked')">
                  <mat-icon [svgIcon]="IconsFilled.Bookmark" />
                </sb-tooltip>
              }
              @if (comment().isHidden) {
                <sb-tooltip position="above" [content]="t('comments.fields.isHidden')">
                  <mat-icon [svgIcon]="Icons.VisibilityOff" />
                </sb-tooltip>
              }
              @if (comment().commentCount; as commentCount) {
                <sb-tooltip position="above" [content]="t('shared.ui.Replies')">
                  <div class="flex items-center gap-1">
                    <mat-icon [svgIcon]="IconsFilled.Reply" />
                    <sb-text variant="bodyMedium" noMargin>
                      {{ commentCount }}
                    </sb-text>
                  </div>
                </sb-tooltip>
              }
              <sb-tooltip position="above" [content]="t('shared.ui.Likes')">
                <div class="flex items-center gap-1">
                  <mat-icon [svgIcon]="IconsFilled.Favorite" />
                  <sb-text variant="bodyMedium" noMargin>
                    {{ comment().likeCount }}
                  </sb-text>
                </div>
              </sb-tooltip>
            </div>
          </div>
        </ng-template>
      } @else {
        <ng-template #content>
          <div class="grid items-center gap-4" [ngStyle]="{ gridTemplateColumns: '1fr min-content' }">
            <div class="max-w-full overflow-hidden">
              <sb-text variant="bodyMedium" [innerHTML]="comment().message" truncate />
              <div class="flex w-fit gap-4">
                <div class="flex items-center gap-1">
                  <mat-icon [svgIcon]="IconsFilled.Person" [ngStyle]="{ width: '14px', height: '14px' }" />
                  <sb-text variant="label" muted noMargin>
                    {{ '@' + comment().authorName }}
                  </sb-text>
                </div>
                <div class="flex items-center gap-1">
                  <mat-icon [svgIcon]="IconsFilled.Event" [ngStyle]="{ width: '14px', height: '14px' }" />
                  <sb-text variant="label" muted noMargin>
                    {{ comment().publishedAt | translocoDate }}
                  </sb-text>
                </div>
                <sb-tooltip position="above" [content]="t('shared.ui.Likes')">
                  <div class="flex items-center gap-1">
                    <mat-icon [svgIcon]="IconsFilled.Favorite" [ngStyle]="{ width: '14px', height: '14px' }" />
                    <sb-text variant="label" muted noMargin>
                      {{ comment().likeCount }}
                    </sb-text>
                  </div>
                </sb-tooltip>
                <!-- @if (comment().channelReplyCount) { -->
                <!--   <div class="flex items-center gap-1 overflow-hidden"> -->
                <!--     <sb-channel-logo size="xxs" [channel]="userService.getCurrentChannel()" hideType /> -->
                <!--     <sb-text variant="label" muted noMargin truncate> -->
                <!--       {{ t('comments.ui.ChannelHasReplied', { count: comment().channelReplyCount }) }} -->
                <!--     </sb-text> -->
                <!--   </div> -->
                <!-- } -->
              </div>
            </div>
            <div class="flex gap-2">
              <sb-tooltip [content]="t('shared.ui.Sentiment')">
                <sb-sentiment-icon [sentiment]="sentiment()" variant="text" />
              </sb-tooltip>
              @if (!hideActions()) {
                <sb-button
                  variant="icon"
                  [icon]="isBookmarked() ? IconsFilled.Bookmark : Icons.Bookmark"
                  [tooltip]="t('comments.actions.' + (isBookmarked() ? 'RemoveFromBookmarks' : 'AddToBookmarks'))"
                  (sbClick)="onBookmarkClick($event)"
                  [disabled]="toggleBookmark.isPending()"
                />
              }
            </div>
          </div>
        </ng-template>
      }
    </sb-card>
  `,
  styles: ``,
})
export class CommentItemComponent {
  comment = input.required<Comment>()
  variant = input<'default' | 'compact'>('default')
  hideBookmark = input(false, { transform: booleanAttribute })
  hideInReview = input(false, { transform: booleanAttribute })
  hideThreadToggle = input(false, { transform: coerceBooleanProperty })
  hideActions = input(false, { transform: booleanAttribute })
  selected = model<Comment>()
  selectable = input(false, { transform: coerceBooleanProperty })
  protected readonly Icons = IconOutlined
  protected readonly IconsFilled = Icon
  protected snackbar = inject(SnackbarService)
  protected userService = inject(UserService)
  #api = inject(CommentsApi)
  #queryClient = injectQueryClient()
  #translateService = inject(TranslocoService)
  drawerService = inject(DrawerService<CommentThreadComponent>)

  menuOptions = computed<CommentMenuItem[]>(() => [
    {
      label: this.#translateService.translate(
        `comments.actions.${this.isBookmarked() ? 'RemoveFromBookmarks' : 'AddToBookmarks'}`,
      ),
      icon: this.isBookmarked() ? Icon.Bookmark : IconOutlined.Bookmark,
      action: { type: 'TOGGLE_BOOKMARK' },
    },
    {
      label: this.#translateService.translate('comments.actions.ChangeSentiment'),
      icon: IconOutlined.SentimentSettings,
      children: [
        {
          label: this.#translateService.translate('sentiment.ui.Positive'),
          icon: IconOutlined.SentimentPositive,
          action: { type: 'CHANGE_SENTIMENT', args: { sentiment: 'POSITIVE' } },
        },
        {
          label: this.#translateService.translate('sentiment.ui.Mixed'),
          icon: IconOutlined.SentimentMixed,
          action: { type: 'CHANGE_SENTIMENT', args: { sentiment: 'MIXED' } },
        },

        {
          label: this.#translateService.translate('sentiment.ui.Neutral'),
          icon: IconOutlined.SentimentNeutral,
          action: { type: 'CHANGE_SENTIMENT', args: { sentiment: 'NEUTRAL' } },
        },
        {
          label: this.#translateService.translate('sentiment.ui.Negative'),
          icon: IconOutlined.SentimentNegative,
          action: { type: 'CHANGE_SENTIMENT', args: { sentiment: 'NEGATIVE' } },
        },
      ],
    },
  ])
  sentiment = computed<Sentiment>(() => this.comment().sentiment?.toLowerCase() as Sentiment)
  hasThread = computed(() => this.comment().commentCount > 0 || this.comment().parentId)
  contentStyles = computed(() => (this.variant() === 'default' ? { paddingTop: 0 } : {}))

  isBookmarked = computed<boolean>(() => {
    // for optimistick updates
    if (this.toggleBookmark.isPending() || this.toggleBookmark.isSuccess()) {
      return !this.comment().isBookmarked
    } else {
      return this.comment().isBookmarked
    }
  })

  ownerChannel = computed(() => {
    const user = this.userService.getCurrentUser()
    if (user instanceof User) {
      const project = user?.getProjectById(this.comment().projectId)
      return project?.getChannelsByType(this.comment().channelType)[0] ?? null
    }
    return null
  })

  authorIsOwner = computed(
    () =>
      this.comment().authorIsOwner ||
      this.comment().authorId === this.ownerChannel()?.instagramId ||
      this.comment().authorId === this.ownerChannel()?.facebookId,
  )

  toggleBookmark = injectMutation(() => ({
    mutationFn: (comment: Comment) => firstValueFrom(this.#api.toggleBookmark(comment.id)),
    onSuccess: ({ data }: { data: Comment }) => {
      this.#queryClient.invalidateQueries({ queryKey: ['comments'] })
      this.snackbar.open(
        this.#translateService.translate(
          `comments.success.${data.isBookmarked ? 'CommentAddedToBookmarks' : 'CommentRemovedFromBookmarks'}`,
        ),
      )
    },
  }))

  changeSentiment = injectMutation(() => ({
    mutationFn: ({ commentId, sentiment }: ChangeSentimentPayload) =>
      firstValueFrom(this.#api.changeSentiment(commentId, sentiment)),
    onSuccess: () => {
      this.#queryClient.invalidateQueries({ queryKey: ['comments'] })
      this.snackbar.open(this.#translateService.translate('comments.success.CommentSentimentProposed'))
    },
  }))

  onBookmarkClick = (event?: Event) => {
    if (event) {
      event.stopPropagation()
      event.preventDefault()
    }
    this.toggleBookmark.mutate(this.comment())
  }

  onOpenThread = () => {
    this.drawerService.setDrawerContent(CommentThreadComponent, { commentId: this.comment().id })
    this.drawerService.openDrawer()
  }

  onCommentClick = () => {
    if (this.selectable()) {
      this.selected.set(this.comment())
      return
    }
    if (!this.hideThreadToggle() && this.hasThread() && this.variant() === 'compact') {
      this.onOpenThread()
    }
  }

  handleAction = (payload: CommentMenuItem) => {
    switch (payload.action.type) {
      case 'TOGGLE_BOOKMARK':
        this.onBookmarkClick()
        break
      case 'CHANGE_SENTIMENT':
        this.changeSentiment.mutate({ commentId: this.comment().id, sentiment: payload.action.args.sentiment })
        break
    }
  }
}
