import { JsonPipe } from '@angular/common'
import { Component, booleanAttribute, computed, inject, input, output } from '@angular/core'
import { ReactiveFormsModule } from '@angular/forms'
import { MatChip, MatChipRemove, MatChipRow } from '@angular/material/chips'
import { MatIcon } from '@angular/material/icon'
import { MatSlideToggleChange, MatSlideToggleModule } from '@angular/material/slide-toggle'
import { MatToolbarModule } from '@angular/material/toolbar'
import { TranslocoModule, TranslocoService } from '@ngneat/transloco'
import { isNil } from 'ramda'
import { SearchBarComponent } from 'src/app/common/components/filters/search-bar/search-bar.component'
import { Icon, IconOutlined } from 'src/app/common/icons'
import { TextTruncatePipe } from 'src/app/common/pipes/text-truncate.pipe'
import { BoxComponent } from 'src/app/common/ui/box/box.component'
import { ButtonComponent } from 'src/app/common/ui/button/button.component'
import { MenuComponent } from 'src/app/common/ui/menu/menu.component'
import { TextComponent } from 'src/app/common/ui/text/text.component'

import { DialogService } from '~core/services/ui/dialog.service'
import logger from '~core/utils/logger'
import { capitalize, upperFirst } from '~core/utils/text'
import { CommentsFilterFormComponent } from '~features/comments/components/comments-filter-form/comments-filter-form.component'
import { CommentFilters } from '~features/comments/types'

@Component({
  selector: 'sb-comment-list-toolbar',
  standalone: true,
  template: `
    <ng-container *transloco="let t">
      <mat-toolbar class="bg-transparent">
        <mat-toolbar-row>
          <div class="flex w-full items-center justify-between gap-10">
            <sb-box direction="row" items="center" gap="1rem" grow="1">
              <!-- Search bar with debounce and show channel comments switch -->
              <sb-search-bar
                [placeholder]="t('comments.ui.SearchByMessage')"
                (search)="onSearch($event)"
                debounce="400"
              />
              @if (!noChannelFilter()) {
                <mat-slide-toggle
                  [checked]="filters().showChannelComments"
                  (change)="onToggleShowChannelComments($event)"
                  focus="false"
                  color="primary"
                >
                  <sb-text noMargin>
                    {{ t('comments.ui.ShowChannelComments') }}
                  </sb-text>
                </mat-slide-toggle>
              }
            </sb-box>

            <sb-box direction="row" items="center" gap="20px" overflow="visible">
              <div class="flex items-center">
                <!-- Sorting -->
                <sb-menu
                  class="mr-2 max-w-xs"
                  [options]="orderOptions"
                  xPosition="before"
                  (selectionChanged)="onOrderChanged($event)"
                  selectable
                  optionLabelKey="name"
                  fill
                >
                  <ng-template #labelTemplate>
                    <sb-button variant="square" [icon]="Icons.ArrowDropDown" iconEnd>
                      <mat-icon [svgIcon]="Icons.Sort" />
                      <span class="text-truncate">{{ t('shared.ui.Sort') }}: {{ orderLabel() }}</span>
                    </sb-button>
                  </ng-template>
                </sb-menu>
                <!-- Filters -->
                <sb-button variant="square" (sbClick)="onOpenFilterForm()">
                  <mat-icon [svgIcon]="hasFilters() ? IconsFilled.FilterAlt : Icons.FilterAlt" />
                  <span class="text-truncate">{{ t('shared.ui.Filter') }}</span>
                </sb-button>
              </div>
            </sb-box>
          </div>
          @if (hasFilters()) {
            <sb-box direction="row" items="center" gap=".5rem" pad=".5rem 0 0" fill>
              <!-- Active filters -->
              <div class="flex flex-wrap items-center gap-4">
                @for (filter of chipsFilters(); track filter) {
                  <mat-chip (removed)="removeFilter(filter.key)">
                    <div class="flex items-center justify-between">
                      <sb-text variant="labelLarge" noMargin inline class="inline-flex">
                        {{ filter.labelKey }}: {{ filter.label }}
                      </sb-text>
                      <button matChipRemove [attr.aria-label]="'remove ' + filter.label">
                        <mat-icon [svgIcon]="Icons.Close" />
                      </button>
                    </div>
                  </mat-chip>
                }
              </div>
            </sb-box>
          }
        </mat-toolbar-row>
      </mat-toolbar>
    </ng-container>
  `,
  styles: `
    @use 'variables' as var;
    @use 'mixins';

    mat-toolbar {
      background-color: transparent;

      mat-toolbar-row {
        padding: 0 0 1.5rem 0;
        min-height: var(--mat-toolbar-standard-height);
        height: auto;
        flex-direction: column;
        justify-content: center;
      }
    }
  `,
  imports: [
    TranslocoModule,
    MatToolbarModule,
    JsonPipe,
    ButtonComponent,
    MenuComponent,
    TextComponent,
    BoxComponent,
    MatIcon,
    MatChipRemove,
    MatChipRow,
    MatChip,
    TextTruncatePipe,
    SearchBarComponent,
    MatSlideToggleModule,
    ReactiveFormsModule,
  ],
})
export class CommentListToolbarComponent {
  #translateService = inject(TranslocoService)
  #dialogService = inject(DialogService)
  orderOptions = this.setOrderOptions()
  orderLabel = computed(() => this.orderOptions.find((o) => o.value === this.filters().orderBy).name)
  filters = input<CommentFilters>({})
  noChannelFilter = input(false, { transform: booleanAttribute })
  filtersChange = output<CommentFilters>()
  hiddenFilters = ['orderBy', 'message', 'showChannelComments']
  protected readonly Icons = IconOutlined
  protected readonly IconsFilled = Icon

  hasFilters = computed(() => {
    return Object.entries(this.filters()).filter(([k, v]) => !(this.hiddenFilters.includes(k) || isNil(v))).length > 0
  })

  chipsFilters = computed(() => {
    return Object.entries(this.filters())
      .filter(([k, v]) => !(this.hiddenFilters.includes(k) || isNil(v)))
      .map(([key, value]) => {
        let label = ''
        let labelKey: string = this.#translateService.translate(`comments.fields.${key}`)
        if (!labelKey) labelKey = this.#translateService.translate(`comments.ui.${upperFirst(key)}`)

        switch (key) {
          case 'message':
            label = value.toString().slice(0, 20) + '...'
            break
          case 'sentiment':
            ;(value as CommentFilters['sentiment']).forEach((v, i) => {
              label += (i > 0 ? ', ' : '') + this.#translateService.translate(`sentiment.ui.${capitalize(v)}`)
            })
            break
          default:
            const yes = this.#translateService.translate('shared.ui.Yes')
            const no = this.#translateService.translate('shared.ui.No')
            label = typeof value === 'boolean' ? (value ? yes : no) : value.toString()
        }
        return { key, labelKey, label }
      })
  })

  onOrderChanged = (event: { name: string; value: string }) => {
    this.filtersChange.emit({ ...this.filters(), orderBy: event.value })
  }

  onOpenFilterForm = () => {
    const filterForm = this.#dialogService.open<CommentsFilterFormComponent>(CommentsFilterFormComponent, {
      data: { filters: this.filters() },
      minWidth: '640px',
    })
    filterForm.componentInstance.filtersSubmit.subscribe((filters) => {
      this.filtersChange.emit({ ...this.filters(), orderBy: this.filters().orderBy, ...filters })
      filterForm.close()
    })
  }

  setOrderOptions() {
    return [
      {
        name: this.#translateService.translate('comments.orderings.PublishedAtDesc'),
        value: 'publishedAt:-1',
      },
      {
        name: this.#translateService.translate('comments.orderings.PublishedAtAsc'),
        value: 'publishedAt:1',
      },
      {
        name: this.#translateService.translate('comments.orderings.SentimentDesc'),
        value: 'sentiment:-1',
      },
      {
        name: this.#translateService.translate('comments.orderings.LikesDesc'),
        value: 'likeCount:-1',
      },
      {
        name: this.#translateService.translate('comments.orderings.RepliesDesc'),
        value: 'commentCount:-1',
      },
    ]
  }

  onSearch = (search: string) => {
    logger.debug('onSearch', search)
    this.filtersChange.emit({ ...this.filters(), message: search })
  }

  onToggleShowChannelComments = ({ checked }: MatSlideToggleChange) => {
    this.filtersChange.emit({ ...this.filters(), showChannelComments: checked })
  }

  removeFilter = (filter: string) => {
    logger.debug('Removing filter', filter)
    const filters = { ...this.filters() }
    delete filters[filter]

    this.filtersChange.emit({ ...filters })
  }
}
