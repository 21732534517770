// import { HttpClient, HttpErrorResponse } from '@angular/common/http'
// import { inject } from '@angular/core'
// import { retry } from '@reduxjs/toolkit/query'
import dayjs from 'dayjs'
import { isEmpty, isNil, toPairs } from 'ramda'
import { isNumber } from 'ramda-extension'
// import { fetchBaseQuery } from 'ngrx-rtk-query'
// import { lastValueFrom } from 'rxjs'
//
// import { SnackbarService } from '~core/services/ui/snackbar.service'
// import { environment as env } from '~env'
import { ProjectDateRangeRequest } from 'src/app/common/components/filters/date-range/date-range.model'

//
// export const baseQuery = fetchBaseQuery(
//   (http = inject(HttpClient), snackbar = inject(SnackbarService), environment = env) => {
//     return async (args, { signal }) => {
//       const {
//         url,
//         method = 'get',
//         body = undefined,
//         params = undefined,
//         headers = undefined,
//       } = typeof args === 'string' ? { url: args } : args
//
//       const fullUrl = `${environment.apiEndpoint}${url}`
//
//       const request$ = http.request(method, fullUrl, { body, params })
//       try {
//         const data = await lastValueFrom(request$)
//         return { data }
//       } catch (error) {
//         if (error.status !== 422) {
//           snackbar.warning(error.message)
//         }
//         return { error: { status: (error as HttpErrorResponse).status, data: (error as HttpErrorResponse).message } }
//       }
//     }
//   },
// )
// // export const baseQuery = (): BaseQueryFn<any, any, FetchBaseQueryError> => {
// //   return async (args) => {
// //     if (typeof args === 'string') {
// //       args = { url: args, method: 'get' }
// //     }
// //     const { url, method = 'get', body, headers, params } = args
// //     const http = InjectorInstance.get(HttpClient)
// //     const snackbar = InjectorInstance.get(SnackbarService)
// //
// //     const fullUrl = `${environment.apiEndpoint}${url}`
// //
// //     const request$ = http.request(method, fullUrl, {
// //       body,
// //       headers,
// //       params,
// //     })
// //
// //     try {
// //       const result = await lastValueFrom(request$)
// //       return { data: result }
// //     } catch (error) {
// //       if (error.status !== 422) {
// //         snackbar.warning(error.message)
// //       }
// //       return { error: { data: error.error, status: error.status } }
// //     }
// //   }
// // }
//
// export const baseQueryWithRetry = retry(baseQuery, { maxRetries: 1 })

// export const handleTagInvalidation = (tags: ReadonlyArray<any>) => (result: any, error: any, arg: any, meta: any) => {
//   if (!error) {
//     return tags
//   } else {
//     return []
//   }
// }

export const withPreviousPeriod = (payload: { fields: any } & ProjectDateRangeRequest) => {
  const currentUntil = dayjs(payload.until)
  const currentSince = dayjs(payload.since)
  const newSince = currentSince.subtract(currentUntil.diff(currentSince, 'days'), 'days')
  return {
    ...payload,
    since: newSince.subtract(1, 'day').format('YYYY-MM-DD'),
  }
}

export const filterIn = (field: string, values: string[]) => `"${field}":{"$in":${JSON.stringify(values)}}`

export const filterIContains = (field: string, value: string) => `"${field}":{"$regex":"${value}", "$options": "i"}`

export const filterEquals = (field: string, value: string | number) => `"${field}":${value}`

export const filterStringEquals = (field: string, value: string | number) => `"${field}":"${value}"`

export const filterBoolEquals = (field: string, value: boolean) => `"${field}":${value}`

export const filterNotEquals = (field: string, value: string | number) => `"${field}":{"$ne": "${value}"}`

export const filterGte = (field: string, value: string | number) => `"${field}":{"$gte":${value}}`

export const filterLte = (field: string, value: string | number) => `"${field}":{"$lte":${value}}`

export const filterStringGte = (field: string, value: string) => `"${field}":{"$gte":"${value}"}`

export const filterStringLte = (field: string, value: string) => `"${field}":{"$lte":"${value}"}`

export const filterBetweenInclusive = (field: string, from: number, to: number) =>
  `"$and":[{${filterGte(field, from)}}, {${filterLte(field, to)}}]`

export const filterStringBetweenInclusive = (field: string, from: string, to: string) =>
  `"$and":[{${filterStringGte(field, from)}}, {${filterStringLte(field, to)}}]`

export const filterTagIContains = (field: string, value: string) =>
  `"${field}":{"$elemMatch":{"$regex":"${value}","$options":"i"}}`

export const filterElemMatch = (field: string, value) =>
  `"${field}":{"$elemMatch":{${toPairs(value)
    .map(([k, v]) => `"${k}":${!isNumber(v) ? `"${v}"` : v}`)
    .join(',')}}}`

export const addFilterStringIn = (filters: string[], field: string, values: string[]) =>
  values ? filters.push(filterIn(field, values)) : null

export const addFilterTagIContains = (filters: string[], field: string, value: string) =>
  value ? filters.push(filterTagIContains(field, value)) : null

export const addFilterIContains = (filters: string[], field: string, value: string) =>
  value ? filters.push(filterIContains(field, value)) : null

export const addFilterEquals = (filters: string[], field: string, value: number) =>
  value === undefined ? null : filters.push(filterEquals(field, value))

export const addFilterStringEquals = (filters: string[], field: string, value: string | number) =>
  value ? filters.push(filterStringEquals(field, value)) : null

export const addFilterStringNotEquals = (filters: string[], field: string, value: string | number) =>
  value ? filters.push(filterNotEquals(field, value)) : null

export const addFilterBoolEquals = (filters: string[], field: string, value: boolean) =>
  isNil(value) ? null : filters.push(filterBoolEquals(field, value ? true : false))

export const addFilterGte = (filters: string[], field: string, value: number) =>
  value ? filters.push(filterGte(field, value)) : null

export const addFilterLte = (filters: string[], field: string, value: number) =>
  value ? filters.push(filterLte(field, value)) : null

export const addFilterDateGte = (filters: string[], field: string, value: dayjs.Dayjs) =>
  value ? filters.push(filterGte(field, value.valueOf())) : null

export const addFilterDateLte = (filters: string[], field: string, value: dayjs.Dayjs) =>
  value ? filters.push(filterLte(field, value.valueOf())) : null

export const addFilterDateBetweenInclusive = (filters: string[], field: string, from: dayjs.Dayjs, to: dayjs.Dayjs) =>
  from && to
    ? filters.push(filterStringBetweenInclusive(field, `ISODate(${from.valueOf()})`, `ISODate(${to.valueOf()})`))
    : null

export const filterByFromFilters = (filters: string[]): { filterBy?: string } =>
  isEmpty(filters) ? {} : { filterBy: filters.join(',') }
